<template>
	<div class="docs position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
			<div class="page-header">
				<h1 class="page-title">Документы</h1>

			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";

    export default {
        name: "docs",
        data() {
            return {
                title: "Документы"
            };
        },
        methods: {

        },
        mounted() {
            CommonService.setPageTitle(this.title);
        }
    };
</script>